import { colors } from '../constants';
import '../style.scss';
import typography from '../typography';

export const mainFonts = {
  ...typography,
  colors: {
    ...typography.colors,
    primary: {
      ...typography.colors.primary,
      color: colors.blue[600]
    },
    secondary: {
      ...typography.colors.secondary
    }
  }
};
