import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  // select an img
  & img {
    @media ${({ theme }) => theme.device.mobile} {
      scale: 0.75;
    }
  }
`;
