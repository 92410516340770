import { DEFAULT, LANGUAGES } from 'src/context/Language/config';
import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';

/**
 * It returns the default language's text for the given key
 * @param {ITextLangKeys} key - this is the key of the text you want to get to translate.
 * @returns A function that takes a key and returns a string.
 */
const getDefaultTextLang = (key: ITextLangKeys): string => {
  return LANGUAGES[DEFAULT][key];
};

export default getDefaultTextLang;
