import * as language from 'src/i18n/en';
import { UnauthenticateSessionProofType } from 'src/types/api';

export const NAME = 'DUP';

export const AOA_PROPERTY_SLUG = 'AOA';

export const UNAUTHENTICATED_SESSION_TOKEN_KEY = 'unauthenticated_session_token';

export const URLS_SLUG = {
  leasing_team: {
    name: 'leasing_team',
    path: '/applyonbehalf/',
    link: '/application/applyonbehalf/'
  },
  applicant: { name: 'unauthenticated_user', path: '', link: '/application/apply/' }
};

export const DOCUMENT_TYPES = [
  {
    value: UnauthenticateSessionProofType.Paystub,
    label: language.default.dup_document_type_paystub
  },
  {
    value: UnauthenticateSessionProofType.BankStatement,
    label: language.default.dup_document_type_bank_statement
  },
  {
    value: UnauthenticateSessionProofType.CashAppStatement,
    label: language.default.dup_document_type_cash_app_statement
  },
  {
    value: UnauthenticateSessionProofType.CreditDebitCardStatement,
    label: language.default.dup_document_type_credit_debit_card_statement
  },
  {
    value: UnauthenticateSessionProofType.DepartmentOfVeteransAffairsBenefitLetter,
    label: language.default.dup_document_type_department_of_veterans_affairs_benefit_letter
  },
  {
    value: UnauthenticateSessionProofType.InvestmentAccount,
    label: language.default.dup_document_type_investment_account
  },
  {
    value: UnauthenticateSessionProofType.SocialSecurityBenefitsLetter,
    label: language.default.dup_document_type_social_security_benefits_letter
  },
  {
    value: UnauthenticateSessionProofType.SocialSecurityStatement,
    label: language.default.dup_document_type_social_security_statement
  },
  {
    value: UnauthenticateSessionProofType.TaxTranscript,
    label: language.default.dup_document_type_tax_transcript
  },
  {
    value: UnauthenticateSessionProofType.UtilityBill,
    label: language.default.dup_document_type_utility_bill
  }
] satisfies { value: UnauthenticateSessionProofType; label: string }[];
