/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components/macro';
import { ButtonDisabledStyle } from '.';

export const ButtonComponent = styled.button<any>`
  ${({ theme }) => theme.button};
  ${({ theme, size }) => theme.button.sizes[size]};
  ${({ theme, color }) => theme.button.colors[color]};
  ${({ theme, variant, color }) => theme.button.colors[color].variants[variant]};
  :hover {
    ${({ theme, variant, color }) => theme.button.colors[color].variants[variant].hover};
    border-color: none;
  }

  :disabled {
    ${({ theme, disabledStyle }) => {
      if (disabledStyle === ButtonDisabledStyle.white) {
        return css`
          cursor: default;
          color: ${theme.color.gray[300]};
          background: ${theme.color.white};
          border-color: ${theme.color.white};
          svg {
            path {
              fill: ${theme.color.gray[300]};
            }
          }
        `;
      }
      if (disabledStyle === ButtonDisabledStyle.whiteWithBorder) {
        return css`
          cursor: default;
          color: ${theme.color.gray[300]};
          background: ${theme.color.white};
          border-color: ${theme.color.gray[300]};
          svg {
            path {
              fill: ${theme.color.gray[300]};
            }
          }
        `;
      }
      if (disabledStyle === ButtonDisabledStyle.gray) {
        return css`
          cursor: default;
          color: ${theme.color.gray[300]};
          background: ${theme.color.white};
          border-color: ${theme.color.white};
          svg {
            path {
              fill: ${theme.color.gray[300]};
            }
          }
        `;
      }
      if (disabledStyle === ButtonDisabledStyle.transparent) {
        return css`
          cursor: default;
          color: ${theme.color.gray[300]};
          background: transparent;
          border-color: transparent;
          svg {
            path {
              fill: ${theme.color.gray[300]};
            }
          }
        `;
      }
      return theme.button.disabled;
    }};
  }

  div {
    display: flex;
  }
`;

export const LoadingComponent = styled.div`
  margin-right: 0.5rem;
`;
