import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  padding-top: 1rem;
  overflow-y: auto;
  margin-bottom: 1rem;

  & .label-checkbox {
    color: #012c57;
    font-family: 'PT Serif';
    font-size: 12px;
    line-height: 20px;
  }
`;
