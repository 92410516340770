import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
`;

export const OptionContainer = styled.div<{ rows: number; listPosition?: 'top' | 'bottom' }>`
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  background: #fff;
  margin: 0;
  margin-top: -0.099rem;
  border: 0.063rem solid ${({ theme }) => theme.color.gray[200]};
  border-radius: 0 0 0.188rem 0.188rem;
  height: ${({ rows }) => (rows > 6 ? '12.5rem' : 'auto')};
  top: ${({ rows, listPosition }) => listPosition === 'top' && (rows > 6 ? '-12.3rem' : 'auto')};
  overflow-y: ${({ rows }) => (rows > 6 ? 'scroll' : 'hidden')};

  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
      border-bottom: 0.063rem solid ${({ theme }) => theme.color.gray[100]};

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background: ${({ theme }) => theme.color.blue[100]};

        button {
          svg {
            color: ${({ theme }) => theme.color.blue[700]};
          }
        }
      }

      button {
        padding: 0.625rem 0.75rem;
        text-decoration: none;
        width: 100%;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-align: left;
        ${({ theme }) => theme.font.normal};
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          margin-left: 0.25rem;
        }
      }

      &.selected {
        button {
          ${({ theme }) => theme.font.bold};
          color: ${({ theme }) => theme.color.blue[700]};

          svg {
            color: ${({ theme }) => theme.color.blue[700]};
          }
        }
      }

      &.placeholder {
        button {
          color: ${({ theme }) => theme.color.gray[600]};
          font-weight: 400;
        }
      }
    }
  }
`;

export const HelperText = styled.div`
  margin-top: 0.195rem;
  margin-left: 0.5rem;

  span {
    line-height: 0.8rem;
    padding-top: 0.5rem;
  }
`;

export const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  border: 0;
  padding: 8px 0;
  background: none;
  cursor: pointer;

  .label {
    ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.color.gray[900]};
    margin-left: 0.375rem;
    text-align: left;
  }
  .selectAll {
    font-weight: bold;
  }
  input {
    height: 17px;
    width: 17px;
  }
`;

export const StyledDiv = styled.div`
  padding: 35px;
`;
