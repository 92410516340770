import React from 'react';

interface CircleProps {
  activeColor?: string;
  children: React.ReactNode;
  isActive?: boolean;
}

const Circle: React.FC<CircleProps> = ({
  children,
  isActive,
  activeColor = 'black'
}: CircleProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle
          cx="12"
          cy="12"
          r="11"
          stroke={isActive ? activeColor : '#012C57'}
          strokeWidth="2"
        ></circle>
        <text
          textAnchor="middle"
          x="12"
          y="12"
          dy="0.35em"
          fontSize="18"
          fill={isActive ? activeColor : 'black'}
        >
          {children}
        </text>
      </g>
    </svg>
  );
};

export default Circle;
