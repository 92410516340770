import styled from 'styled-components/macro';

export const Container = styled.div`
  .checkboxRow {
    display: flex;
  }

  .label {
    display: block;
    ${({ theme }) => theme.font.normal};
    font-size: 1rem;
    color: ${({ theme }) => theme.color.black.normal};
    margin-left: 0.375rem;
    text-align: left;
    line-height: 1.5rem;
    letter-spacing: 0.00938em;
  }

  .buttonContainer {
    margin-top: 1rem;
  }

  #submit {
    height: 3rem;
  }

  .toolTipDirection.top {
    transform: translateY(-110%) translateX(-6%);
    &:after {
      left: 1.2rem;
    }
  }
`;
