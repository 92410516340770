import PropTypes from 'prop-types';
import React from 'react';
import { Label as StyledLabel } from './styles';

export enum LinkTarget {
  blank = '_blank',
  self = '_self'
}

export type LinkProps = {
  to: string;
  children: React.ReactNode;
  target?: LinkTarget;
  isExternal?: boolean;
  isDisabled?: boolean;
  name?: string;
  label?: string;
};

const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const { to, name, children, target, isExternal, isDisabled, label } = props;

  const config = {
    name,
    id: name,
    target: isDisabled ? LinkTarget.self : target,
    role: 'button',
    'aria-label': label,
    'aria-pressed': true,
    'data-disabled': isDisabled
  };

  if (isExternal) {
    return (
      <StyledLabel {...config} rel="noopener noreferrer" as="a" href={isDisabled ? '#' : to}>
        {children}
      </StyledLabel>
    );
  }

  return (
    <StyledLabel isDisabled={isDisabled} {...config} to={isDisabled ? '#' : to}>
      {children}
    </StyledLabel>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  target: PropTypes.oneOf<LinkTarget>(Object.values(LinkTarget)),
  isExternal: PropTypes.bool,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string
};

Link.defaultProps = {
  children: 'Link',
  to: './',
  target: LinkTarget.self,
  isExternal: false,
  isDisabled: false,
  name: 'Link',
  label: 'label'
};

export default Link;
