/**
 * To add a new language you must follow the next steps:
 * 1. Add the file with the translations in the line below
 * 2. Add the language into the variable "LANGUAGES"
 */
import en from '../../i18n/en';
import es from '../../i18n/es';

export const LANGUAGES = { EN: en, ES: es };

/**
 * To set the default language just update the variable DEFAULT below
 */
export const DEFAULT = 'EN';
