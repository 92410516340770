import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { ButtonVariant } from 'src/components/atoms/Button';
import useLanguage from 'src/context/Language/useLanguage';
import { Information } from 'src/features/DUP/helpers/getInformation';
import { useFormApplicant } from 'src/hooks/useFormApplicant';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { StyledButton as Button } from 'src/pages/DUPPage/styles';
import { DupApplicationType } from 'src/types/api';
import { Container } from './styles';

export const WizardFormApplicant = (props: DupScreenStepProps & { information: Information }) => {
  const { translate: t } = useLanguage();
  const [isFormValid, setIsFormValid] = useState(false);
  const {
    form,
    UnitInput,
    FirstNameInput,
    MiddleInitialInput,
    LastNameInput,
    EmailInput,
    PhoneInput,
    HavePrevSubmitRow
  } = useFormApplicant({
    ...props,
    inputProps: { alwaysShowLabel: true, style: { width: '100%' } },
    setIsFormValid,
    type: DupApplicationType.UNAUTHENTICATED_USER
  });

  return (
    <>
      <Container>
        <FormProvider {...form}>
          {UnitInput}
          {FirstNameInput}
          {MiddleInitialInput}
          {LastNameInput}
          {EmailInput}
          {PhoneInput}
          {HavePrevSubmitRow}
        </FormProvider>
      </Container>
      <Button
        name="submit"
        isDisabled={!isFormValid}
        onClick={() => props.onFinished()}
        variant={ButtonVariant.contained}
      >
        {t('dup_button_label_next')}
      </Button>
    </>
  );
};
