import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import { LanguageProvider } from 'src/context/Language';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP';
import { FeatureFlagKeys, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { makeRequest, useResource } from 'src/hooks/useResource';
import { DUPWizard } from 'src/pages/DUPPage/DUPWizard';
import { CreateApplicationResponse, DupApplicationType, SessionApplication } from 'src/types/api';
import { DUPPage } from './DUPPage';

async function createApplication(
  type: DupApplicationType,
  args: { companyId: string; propertyId: string; applicantIdentifier?: string }
) {
  const resp = await makeRequest<CreateApplicationResponse | { error: string }>(
    `/session/public?applicationType=${type}`,
    'POST',
    {
      companyShortId: args.companyId,
      propertyShortId: args.propertyId,
      applicantIdentifier: args.applicantIdentifier
    }
  );

  if ('token' in resp) {
    localStorage.setItem(UNAUTHENTICATED_SESSION_TOKEN_KEY, resp.token || '');
  } else if ('error' in resp) {
    window.location.href = `/?error=${encodeURIComponent(resp.error)}`;
  }
}

export const DUPPageProvider = ({ type }: { type: DupApplicationType }) => {
  const { companyId, propertyId } = useParams();
  const { search } = useLocation();
  const applicantIdentifier = new URLSearchParams(search).get('applicantIdentifier');

  const { loading, data: isNewDUPExperienceActiveFF } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.applicantDupExperienceRedesign,
    filter: `propertyId=${propertyId}`
  });

  const [application, { put, refresh }] = useResource<SessionApplication | { error: string }>(
    `/session/application`,
    { silent: true }
  );

  React.useEffect(() => {
    if (companyId && propertyId && application) {
      if (
        // error usually means the session has expired
        'error' in application ||
        // an applicant could have applications at multiple properties, so we're just making sure we are showing the correct one
        (!('error' in application) && application?.propertyShortId !== propertyId) ||
        // ensuring that the application is for the correct applicant
        (!('error' in application) &&
          applicantIdentifier &&
          applicantIdentifier !== application?.applicantDetailId)
      )
        createApplication(type, {
          propertyId,
          companyId,
          applicantIdentifier: applicantIdentifier || undefined
        }).then(refresh);
    }
  }, [type, application, companyId, propertyId, applicantIdentifier, refresh]);

  if (
    loading ||
    !application ||
    'error' in application ||
    application?.propertyShortId !== propertyId
  ) {
    return <Loader isFixed />;
  }

  const onUpdateApplication = async (updates: Partial<SessionApplication>) => {
    await put(updates);
    return await refresh();
  };

  return (
    <LanguageProvider>
      {isNewDUPExperienceActiveFF && type === DupApplicationType.UNAUTHENTICATED_USER ? (
        <DUPWizard
          type={type}
          application={application}
          onUpdateApplication={onUpdateApplication}
        />
      ) : (
        <DUPPage type={type} application={application} onUpdateApplication={onUpdateApplication} />
      )}
    </LanguageProvider>
  );
};
