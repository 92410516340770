import styled from 'styled-components';

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: 4px;
  background: #f8f8f8;
  padding: 1.5rem;
  margin: 1rem;

  color: #000;
  line-height: 2rem;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat';

  & span {
    width: 100%;
    margin-bottom: 8px;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 1rem;
  }
`;

export const StyledUl = styled.ul`
  list-style-type: none; /* Remove default bullets */
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;

  & li {
    position: relative;
    margin-left: -1rem;
  }

  & li:last-child {
    margin-bottom: 0;
  }

  & li::before {
    content: '•'; /* Bullet character */
    color: #000; /* Bullet color */
    font-size: 1.25rem; /* Bullet size */
    max-width: 10px; /* Width to create space between bullet and text */
    position: absolute; /* Position it absolutely */
    left: -1rem; /* Align it to the left of the text */
    top: 0; /* Align to the top of the li element */
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding-inline-start: 2rem;

    & li {
      margin-bottom: 1.2rem;
    }

    & li::before {
      left: -1rem; /* Align it to the left of the text */
    }
  }
`;
