import { ILanguage, StateData } from './types';

export enum LangActionType {
  SET_LANGUAGE = 'SET_LANGUAGE'
}

interface SetLanguageAction {
  type: typeof LangActionType.SET_LANGUAGE;
  payload: ILanguage;
}

export const langReducer = (state: StateData, action: SetLanguageAction): StateData => {
  switch (action.type) {
    case LangActionType.SET_LANGUAGE:
      return {
        language: action.payload
      };
    default:
      return state;
  }
};
