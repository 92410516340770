import Text from 'src/components/atoms/Text';
import { Color } from 'src/pages/DUPPage/constants/color';
import { DupApplicationType } from 'src/types/api';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ type: DupApplicationType }>`
  background: ${({ type }) => Color[type].light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 2rem 2rem 2rem;
  gap: 1rem;
  text-align: center;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }
`;

export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

const textStyles = css`
  font-family: 'Montserrat';
  color: #012c57;
  font-weight: 700;
  text-align: center;
`;

export const Title = styled.div`
  ${textStyles}
  font-size: 1rem;
  position: absolute;
  width: 100%;
`;

export const StyledText = styled(Text)`
  ${textStyles}
  font-size: 25px;
  line-height: 15px;
`;
