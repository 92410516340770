import buttons from '../buttons';
import { colors } from '../constants';
import '../style.scss';

export const mainButtons = {
  ...buttons,
  colors: {
    primary: {
      variants: {
        contained: {
          ...buttons.colors.primary.variants.contained,
          color: colors.white,
          background: colors.blue[700],
          borderColor: colors.blue[700],
          hover: {
            ...buttons.colors.primary.variants.contained.hover,
            color: colors.white,
            background: colors.blue[600],
            borderColor: colors.blue[600]
          }
        },
        outline: {
          ...buttons.colors.primary.variants.outline,
          color: colors.blue[600],
          hover: {
            ...buttons.colors.primary.variants.outline.hover,
            color: colors.blue[600],
            background: colors.blue[100],
            borderColor: colors.blue[600]
          }
        },
        ghost: {
          ...buttons.colors.primary.variants.ghost,
          color: colors.blue[600],
          hover: {
            ...buttons.colors.primary.variants.ghost.hover,
            color: colors.blue[600],
            background: colors.blue[100],
            borderColor: colors.blue[100]
          }
        }
      }
    },
    secondary: {
      variants: {
        contained: {
          ...buttons.colors.secondary.variants.contained,
          color: colors.white,
          background: colors.purple[700],
          borderColor: colors.purple[700],
          hover: {
            ...buttons.colors.secondary.variants.contained.hover,
            color: colors.purple[700],
            background: colors.purple[500]
          }
        },
        outline: {
          ...buttons.colors.secondary.variants.outline,
          color: colors.purple[700],
          hover: {
            ...buttons.colors.secondary.variants.outline.hover,
            color: colors.purple[700],
            background: colors.purple[500],
            borderColor: colors.purple[700]
          }
        },
        ghost: {
          ...buttons.colors.secondary.variants.ghost,
          color: colors.purple[700],
          hover: {
            ...buttons.colors.secondary.variants.ghost.hover,
            color: colors.white,
            background: colors.purple[700],
            borderColor: colors.purple[700]
          }
        }
      }
    },
    tertiary: {
      variants: {
        contained: {
          ...buttons.colors.tertiary.variants.contained,
          color: colors.white,
          background: colors.gray[600],
          borderColor: colors.gray[600],
          hover: {
            ...buttons.colors.tertiary.variants.contained.hover,
            color: colors.white,
            background: colors.gray[500],
            borderColor: colors.gray[500]
          }
        },
        outline: {
          ...buttons.colors.tertiary.variants.outline,
          color: colors.gray[600],
          hover: {
            ...buttons.colors.tertiary.variants.outline.hover,
            color: colors.gray[600],
            background: colors.gray[100],
            borderColor: colors.gray[600]
          },
          active: {
            borderColor: colors.blue[600]
          }
        },
        ghost: {
          ...buttons.colors.tertiary.variants.ghost,
          color: colors.gray[300],
          hover: {
            ...buttons.colors.tertiary.variants.ghost.hover,
            color: colors.gray[600],
            background: colors.gray[100],
            borderColor: colors.gray[100]
          }
        }
      }
    },
    cancel: {
      variants: {
        contained: {
          ...buttons.colors.cancel.variants.contained,
          color: colors.white,
          background: colors.red[700],
          borderColor: colors.red[700],
          hover: {
            ...buttons.colors.cancel.variants.contained.hover,
            color: colors.white,
            background: colors.red[600],
            borderColor: colors.red[600]
          }
        },
        outline: {
          ...buttons.colors.cancel.variants.outline,
          color: colors.red[700],
          hover: {
            ...buttons.colors.cancel.variants.outline.hover,
            color: colors.red[700],
            background: colors.red[100],
            borderColor: colors.red[700]
          }
        },
        ghost: {
          ...buttons.colors.cancel.variants.ghost,
          color: colors.red[700],
          hover: {
            ...buttons.colors.cancel.variants.ghost.hover,
            color: colors.white,
            background: colors.red[700],
            borderColor: colors.red[700]
          }
        }
      }
    },
    disabled: {
      variants: {
        contained: {
          ...buttons.colors.disabled.variants.contained,
          color: colors.black,
          background: colors.gray[200],
          borderColor: colors.gray[200],
          hover: {
            ...buttons.colors.disabled.variants.contained.hover,
            color: colors.black,
            background: colors.gray[200]
          }
        },
        outline: {
          ...buttons.colors.disabled.variants.outline,
          color: colors.gray[200],
          hover: {
            ...buttons.colors.disabled.variants.outline.hover,
            color: colors.gray[200],
            background: colors.gray[100],
            borderColor: colors.gray[200]
          }
        },
        ghost: {
          ...buttons.colors.disabled.variants.ghost,
          color: colors.gray[200],
          hover: {
            ...buttons.colors.disabled.variants.ghost.hover,
            color: colors.white,
            background: colors.gray[200],
            borderColor: colors.gray[200]
          }
        }
      }
    }
  }
};
