import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';

import { ButtonVariant } from 'src/components/atoms/Button';
import { ProofForBox } from 'src/components/DUP/molecules/BoxProof/BoxProof';
import useLanguage from 'src/context/Language/useLanguage';
import { makeRequest } from 'src/hooks/useResource';

import { Information } from 'src/features/DUP/helpers/getInformation';
import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import {
  CreateProofRequest,
  JobErrorType,
  Proof,
  ProofResponse,
  UnauthenticateSessionProofType
} from 'src/types/api';
import {
  ButtonsContainer,
  Container,
  FileContainer,
  HelpText,
  StyledButton,
  StyledIcon,
  StyledText,
  StyledTrigger,
  SubText,
  UploadsContainer
} from './styles';

import { WizardUploadInstructions } from 'src/components/DUP/molecules/WizardUploadInstructions/WizardUploadInstructions';

const getJobsError = (response: {
  error: string;
  failedChecks?: JobErrorType[];
}): JobErrorType[] => {
  const overSizeError = response.error.includes('File too large');

  if (overSizeError) {
    return ['PDFOver25MBError'];
  }
  if (response.failedChecks) {
    return response.failedChecks;
  }

  return ['GenericError'];
};

export const WizardDocumentUploadSection = (
  props: DupScreenStepProps & {
    information: Information;
    type: UnauthenticateSessionProofType;
    proofs: Proof[];
    refresh?: () => Promise<Proof[]>;
  }
) => {
  const { name, property, onFinished, setActiveStep, type, proofs, refresh } = props;

  const { translate: t } = useLanguage();

  const [uploadingFiles, setUploadingFiles] = useState<ProofForBox[]>([]);
  const [proofIsProcessing, setProofIsProcessing] = useState(false);

  const handleUploadFiles = useCallback(
    async (proofsToUpload: CreateProofRequest[]) => {
      if (proofsToUpload.length > FILES_TO_UPLOAD.MAX_FILES) {
        alert(t('dup_proof_max_files', FILES_TO_UPLOAD.MAX_FILES.toString()));
        return [];
      }

      setProofIsProcessing(true);

      const failedDocs: ProofForBox[] = [];
      for (const { upload, type } of proofsToUpload) {
        const formData = new FormData();
        formData.append('upload', upload);
        formData.append('type', type);
        const response = await makeRequest<
          ProofResponse | { error: string; failedChecks: JobErrorType[] }
        >(`/session/documents?checks=true`, 'POST', formData, true);

        if ('error' in response) {
          const jobs_error = getJobsError(response);

          failedDocs.push({
            id: upload.name,
            type,
            jobs_error,
            thumb: ''
          });
        }
      }

      await refresh?.();
      setProofIsProcessing(false);

      return failedDocs;
    },
    [refresh, t]
  );

  const onFilesSelect = useCallback(
    async (chosenFiles: File[]) => {
      const placeholderFiles = chosenFiles.map(
        (upload: File): ProofForBox => ({
          id: upload.name,
          type: type,
          thumb: '',
          jobs_error: [],
          isReplacing: false,
          isLoading: true
        })
      );

      setUploadingFiles((prevState) => [...prevState, ...placeholderFiles]);

      const failedFiles = await handleUploadFiles(
        chosenFiles.map((upload) => ({ upload, type })) satisfies CreateProofRequest[]
      );

      setUploadingFiles((prevState) => [
        ...prevState.filter((p) => !placeholderFiles.includes(p)),
        ...failedFiles
      ]);
    },
    [handleUploadFiles, type]
  );

  const onDeleteProof = async (proof_id: string) => {
    await makeRequest(`/session/documents/${proof_id}`, 'DELETE');
    await refresh?.();
    setUploadingFiles((prevState) => prevState.filter((f) => f.id !== proof_id));
  };

  // The proofs array are the fully uploaded documents. Mix in documents we're
  // currently uploading to create the displayed set. Some uploading documents
  // replace existing documents.
  const displayed: ProofForBox[] = [
    ...proofs.map((p) => uploadingFiles.find((u) => u.isReplacing && u.id === p.id) || p),
    ...uploadingFiles.filter((u) => !u.isReplacing)
  ].filter((p) => p.type === type);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFilesSelect(acceptedFiles);
    },
    [onFilesSelect]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { application: ['.pdf'] },
    disabled: proofIsProcessing
  });

  const instructionDocumentAmount =
    type === UnauthenticateSessionProofType.Paystub ? property?.paystub : property?.bankStatement;

  return (
    <Container>
      <UploadsContainer {...getRootProps()}>
        <input {...getInputProps()} />

        <StyledIcon icon="upload_cloud" />
        <StyledText>
          <span>{t('dup_wizard_click_to_upload')}</span> {t('dup_wizard_drag_and_drop')}
        </StyledText>
        <SubText>{t('dup_wizard_pdf_only')}</SubText>
      </UploadsContainer>
      {displayed.map((proof) => (
        <FileContainer key={proof.id} justify="space-between" alignItems="center">
          <Row alignItems="center" gap={0.5}>
            <Icon icon="file_grey" />
            <span>{proof.id} doc</span>
          </Row>
          <StyledTrigger onClick={() => onDeleteProof(proof.id)}>
            {' '}
            <Icon icon="delete_outlined" />
          </StyledTrigger>
        </FileContainer>
      ))}
      <WizardUploadInstructions documentsAmount={instructionDocumentAmount} type={type} />
      <HelpText>
        {t('dup_wizard_footer_text')}
        <span>{t('dup_wizard_help_center')}</span>
      </HelpText>
      <ButtonsContainer justify="space-between">
        <StyledButton
          name="back"
          isDisabled={proofIsProcessing}
          onClick={() => {
            setActiveStep(name === 'step3' ? 1 : 0);
          }}
          variant={ButtonVariant.outline}
        >
          {t('dup_button_label_back')}
        </StyledButton>
        <StyledButton
          name="next"
          isDisabled={proofIsProcessing || (name === 'step3' && !proofs.length)}
          onClick={() => onFinished()}
          variant={ButtonVariant.contained}
        >
          {t('dup_button_label_next')}
        </StyledButton>
      </ButtonsContainer>
    </Container>
  );
};
