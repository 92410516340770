import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';
import HideOnMobile from 'src/components/DUP/atoms/HideInMobile';
import HideOnDesktop from 'src/components/DUP/atoms/HideOnDesktop';
import useLanguage from 'src/context/Language/useLanguage';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { Container, Row } from './styles';
const PUBLIC_URL = process.env.PUBLIC_URL;

const Success = (props: DupScreenStepProps) => {
  const { property } = props;
  const { translate: t } = useLanguage();
  const successText = `${t('dup_wizard_success_congrats')} ${property?.name} ${t(
    'dup_wizard_success_message'
  )}`;
  return (
    <Container>
      <Row>
        <img
          src={`${PUBLIC_URL}/assets/images/inspector_squid.png`}
          alt="Inspector Squid Success"
        />
        <img src={`${PUBLIC_URL}/assets/images/Checkmark.png`} alt="Success checkmark" />
      </Row>
      <Row>
        <HideOnMobile>
          <Text isBold variant={TextVariant.h2} align={TextAlign.center}>
            {successText}
          </Text>
        </HideOnMobile>
        <HideOnDesktop>
          <Text isBold variant={TextVariant.h4} align={TextAlign.center}>
            {successText}
          </Text>
        </HideOnDesktop>
      </Row>
    </Container>
  );
};

export default Success;
