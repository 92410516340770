import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { Container, ContainerLoader, Wrapper } from './styles';
const PUBLIC_URL = process.env.PUBLIC_URL;

export type LoaderProps = { isFixed?: boolean };

const Loader: React.FC<LoaderProps> = ({ isFixed = false }) => {
  return (
    <ContainerLoader isFixed={isFixed}>
      <Wrapper>
        <Container>
          <ReactSVG className="square" src={`${PUBLIC_URL}/assets/images/loader-square.svg`} />
          <ReactSVG
            className="triangle_one"
            src={`${PUBLIC_URL}/assets/images/loader-triangle.svg`}
          />
          <ReactSVG
            className="triangle_two"
            src={`${PUBLIC_URL}/assets/images/loader-triangle.svg`}
          />
          <ReactSVG
            className="triangle_three"
            src={`${PUBLIC_URL}/assets/images/loader-triangle.svg`}
          />
        </Container>
      </Wrapper>
    </ContainerLoader>
  );
};

Loader.propTypes = {
  isFixed: PropTypes.bool
};

export default Loader;
