import { colors, fonts } from './constants';
import './style.scss';

const tooltip = {
  main: {
    color: colors.white,
    fontFamily: fonts.normal,
    fontSize: '0.8rem',
    fontWeight: 400,
    margin: '0rem',
    borderRadius: '0.25rem',
    padding: '0.375rem',
    lineHeight: '1rem',
    zIndex: '999',
    letterSpacing: '0.03rem'
  },
  color: colors.gray[700]
};

export default tooltip;
