import styled, { css } from 'styled-components/macro';

export const Section = styled.section<{ padding: string; align: string }>`
  padding: ${({ padding }) => padding};
  max-width: 1280px;
  margin: 0 auto;
  ${({ align }) => {
    if (align === 'center') {
      return css`
        width: 100vh;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      `;
    }
  }};
`;
