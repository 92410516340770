import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import Col from 'src/components/atoms/Row/Col';
import Text from 'src/components/atoms/Text';
import {
  isAdditionalDocumentProofType,
  isIncomeDocumentProofType
} from 'src/components/DUP/molecules/WizardSubmit';
import useLanguage from 'src/context/Language/useLanguage';
import getDocumentTypes from 'src/features/DUP/helpers/getDocumentTypes';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { Proof, SessionApplication } from 'src/types/api';
import {
  DocumentRow,
  EditButton,
  NoDocumentsContainer,
  PersonalInfoContainer,
  ReviewContainer,
  ReviewSectionContent,
  SectionTitle
} from './styles';

const ApplicationInfo = ({
  application,
  setActiveStep,
  proofs
}: Pick<DupScreenStepProps, 'application' | 'setActiveStep'> & { proofs: Proof[] }) => {
  const { translate: t } = useLanguage();

  const incomeDocuments = proofs.filter(isIncomeDocumentProofType);
  const additionalDocuments = proofs.filter(isAdditionalDocumentProofType);

  return (
    <ReviewContainer>
      <ReviewInfoSection
        title={t('dup_wizard_step_1_title')}
        step={1}
        setActiveStep={setActiveStep}
      >
        <PersonalInfo application={application} />
      </ReviewInfoSection>
      <ReviewInfoSection
        title={t('dup_wizard_income_documents')}
        step={2}
        setActiveStep={setActiveStep}
      >
        <DocumentList proofs={incomeDocuments} />
      </ReviewInfoSection>
      <ReviewInfoSection
        title={t('dup_wizard_step_3_title')}
        step={3}
        setActiveStep={setActiveStep}
      >
        <DocumentList proofs={additionalDocuments} />
      </ReviewInfoSection>
    </ReviewContainer>
  );
};

const ReviewInfoSection = ({
  title,
  setActiveStep,
  step,
  children
}: {
  title: string;
  setActiveStep: (step: number) => void;
  step: number;
  children: React.ReactNode;
}) => {
  return (
    <>
      <Line />
      <Col>
        <Row justify="space-between" alignItems="center">
          <SectionTitle>{title}</SectionTitle>
          <EditButton
            name="edit"
            variant={ButtonVariant.outline}
            color={ButtonColor.tertiary}
            onClick={() => setActiveStep(step - 1)}
          >
            Edit
          </EditButton>
        </Row>
        <ReviewSectionContent>{children}</ReviewSectionContent>
      </Col>
    </>
  );
};

const PersonalInfo = ({ application }: { application: SessionApplication }) => {
  const { unit, firstName, middleInitial, lastName, phone, email } = application;
  const { translate: t } = useLanguage();

  return (
    <PersonalInfoContainer>
      {!!unit && (
        <Text>
          {t('dup_wizard_unit')} {unit}
        </Text>
      )}
      <Text>
        {firstName} {middleInitial} {lastName}
      </Text>
      {!!phone && <Text>{phone}</Text>}
      <Text>{email}</Text>
    </PersonalInfoContainer>
  );
};

const DocumentList = ({ proofs }: { proofs: Proof[] }) => {
  const { translate: t } = useLanguage();

  return proofs.length ? (
    <>
      {proofs.map((proof, i) => {
        const label = getDocumentTypes(t).find((type) => type.value === proof.type)?.label;
        return (
          <DocumentRow key={proof.id}>
            <Icon icon="file" />
            {label} {proofs.length > 1 && `#${i + 1}`}
          </DocumentRow>
        );
      })}
    </>
  ) : (
    <NoDocumentsContainer>{t('dup_wizard_no_documents_uploaded')}</NoDocumentsContainer>
  );
};

export default ApplicationInfo;
