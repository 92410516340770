import styled from 'styled-components';

const HideOnDesktop = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`;

export default HideOnDesktop;
