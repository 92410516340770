import useLanguage from 'src/context/Language/useLanguage';

import { UnauthenticateSessionProofType } from 'src/types/api';
import { Instructions, StyledUl } from './styles';

const getTranslationText = (type: string) => {
  if (type === UnauthenticateSessionProofType.Paystub) {
    return 'dup_wizard_instruction_paystubs';
  }
  if (type === UnauthenticateSessionProofType.BankStatement) {
    return 'dup_wizard_instruction_bank_statements';
  }
  return 'dup_wizard_instruction_additional_docs';
};

export const WizardUploadInstructions = ({
  documentsAmount,
  type
}: {
  documentsAmount?: number | null;
  type: UnauthenticateSessionProofType;
}) => {
  const { translate: t } = useLanguage();
  const documentTypeText = getTranslationText(type);

  const documents = documentsAmount?.toString() || '';
  const firstInstruction = documentsAmount
    ? t('dup_wizard_first_instruction', documents, t(documentTypeText))
    : t('dup_wizard_first_instruction_no_amount', t(documentTypeText));

  return (
    <Instructions>
      <span>Instructions:</span>
      <StyledUl>
        <li>{firstInstruction}</li>

        <li>{t('dup_wizard_second_instruction')}</li>

        <li>{t('dup_wizard_third_instruction')}</li>
      </StyledUl>
    </Instructions>
  );
};
