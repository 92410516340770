import PropTypes from 'prop-types';
import { Section as Container } from './styles';

export type SectionProps = {
  children: React.ReactNode;
  padding?: string;
  align?: 'center' | 'top';
};

const Section: React.FC<SectionProps> = ({ children, padding = '3rem', align = 'top' }) => {
  return (
    <Container align={align} padding={padding}>
      {children}
    </Container>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  align: PropTypes.oneOf(['center', 'top'])
};

Section.defaultProps = {
  children: 'Content'
};

export default Section;
