import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface StyledLabelProps {
  isDisabled?: boolean;
}

export const Label = styled(NavLink)<StyledLabelProps>`
  text-decoration: none;
  ${({ theme }) => theme.font.normal};
  ${(props) =>
    !props.isDisabled &&
    css`
      color: ${({ theme }) => theme.color.blue[700]};
      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.color.blue[600]};
      }
    `}
  ${(props) =>
    props.isDisabled &&
    css`
      color: black;
    `}
`;
