import PropTypes from 'prop-types';
import { GapComponent } from './styles';

export type GapProps = {
  height?: number;
};

const Gap: React.FC<GapProps> = (props: GapProps) => {
  return <GapComponent height={props.height} />;
};

Gap.propTypes = {
  height: PropTypes.number
};

Gap.defaultProps = {
  height: 1
};

export default Gap;
