import { useContext } from 'react';
import { LangContext } from 'src/context/Language/LanguageProvider';

const useLanguage = () => {
  const {
    state: { language },
    dispatch: { translate, setLanguage }
  } = useContext(LangContext);

  return {
    translate,
    language,
    setLanguage
  };
};

export default useLanguage;
