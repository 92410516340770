import { fonts } from './constants';
import './style.scss';

const navigation = {
  fontFamily: fonts.normal,
  fontWeight: 400,
  fontSize: '1rem',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textDecoration: 'none',
  borderLeftWidth: '4px',
  borderLeftStyle: 'solid',
  paddingLeft: '1.625rem',
  paddingTop: '0.656rem',
  paddingBottom: '0.656rem',
  whiteSpace: 'nowrap',
  hover: {
    transition: 'all 0.15s linear'
  },
  active: {}
};

export default navigation;
