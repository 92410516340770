import styled from 'styled-components';

export const ListContainer = styled.div``;

export const ListBody = styled.div`
  display: flex;
  padding-bottom: 1rem;
  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const ListIconWrapper = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: start;

    & svg {
      margin-right: 1rem;
      margin-top: 0.15rem;
      width: 1rem;
      height: 1rem;
      path {
        fill: ${({ theme }) => theme.color.blue[900]};
      }
    }
  }
`;

export const ListBulletWrapper = styled.div`
  margin-right: 1rem;
`;
