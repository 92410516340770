import Button, { ButtonVariant } from 'src/components/atoms/Button';
import Text from 'src/components/atoms/Text';
import styled from 'styled-components';

export const WizardDescription = styled(Text)`
  font-size: 1rem;
  text-align: center;
  color: #012c57;
  font-family: 'PT Serif';
  font-weight: 400;
  line-height: 20px;
`;

export const StyledButton = styled(Button)`
  background: ${({ variant }) => (variant === ButtonVariant.contained ? '#32b2fc' : 'unset')};
  border-radius: 10px;
  border: 1px solid
    ${({ variant }) => (variant === ButtonVariant.contained ? '#32b2fc' : '#012C57')};
  font-family: 'Montserrat';
  line-height: 20px;
  padding: 1rem 2.5rem;
  font-size: 16px;
  font-weight: 700;
  color: ${({ variant }) => (variant === ButtonVariant.contained ? '#fff' : '#000')};
`;
