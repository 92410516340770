import { PointLeft, PointRight, Wrapper } from './styles';

const MiniDivider = () => {
  return (
    <Wrapper>
      <PointLeft>.</PointLeft>
      <hr style={{ width: '1.5rem', height: '.125rem', backgroundColor: '#333' }} />
      <PointRight>.</PointRight>
    </Wrapper>
  );
};

export default MiniDivider;
