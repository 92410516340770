/**
 * This header component is intended to be usd with the DUPWizard component.
 */
import { IconColor } from 'src/components/atoms/Icon';
import { TextColor } from 'src/components/atoms/Text';
import { HeaderProps } from 'src/components/DUP/atoms/Header/Header';
import HideOnMobile from 'src/components/DUP/atoms/HideInMobile';
import { LanguageSelector } from 'src/components/organisms/LanguageSelector';
import { Container, StyledText, Title, TitleRow } from './styles';

const WizardHeader = (props: HeaderProps & { propertyName?: string }) => {
  const { type, information, onSetLanguage, propertyName } = props;

  return (
    <Container type={type}>
      <TitleRow>
        <Title>{information.dup_wizard.title}</Title>
        <LanguageSelector
          type={type}
          onSetLanguage={onSetLanguage}
          iconColor={IconColor.gray900}
          textColor={TextColor.initial}
          TextWrapper={HideOnMobile}
        />
      </TitleRow>
      <StyledText>{propertyName}</StyledText>
    </Container>
  );
};

export default WizardHeader;
