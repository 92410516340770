import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { Row } from '../Row';
import Text, { TextColor, TextVariant } from '../Text';
import { ListBody, ListBulletWrapper, ListContainer, ListIconWrapper } from './styles';

export enum ListTypes {
  ORDERED = 'ORDERED',
  UNORDERED = 'UNORDERED',
  BULLET_FREE = 'BULLET_FREE'
}

export type ListItemProps = {
  icon?: React.ReactNode;
  text: string;
};

export type ListProps = React.HTMLAttributes<HTMLDivElement> & {
  type: ListTypes;
  items: ListItemProps[];
};

const List: React.FC<ListProps> = (props: ListProps) => {
  const { items, type } = props;

  return (
    <ListContainer {...props}>
      {items.map((item, idx) => {
        let iconObj: null | object = null;
        switch (type) {
          case ListTypes.ORDERED:
            iconObj = (
              <ListBulletWrapper>
                <Text color={TextColor.gray600} variant={TextVariant.normal}>
                  {`${idx + 1}.`}
                </Text>
              </ListBulletWrapper>
            );
            break;
          case ListTypes.UNORDERED:
            iconObj = (
              <ListIconWrapper>{item.icon || <Icon icon="icon-round-check" />}</ListIconWrapper>
            );
            break;

          default:
            break;
        }

        return (
          <ListBody key={`listItem-${idx}`}>
            <Row>
              {iconObj && (
                <Row.Col size="1.2rem">
                  <>{iconObj}</>
                </Row.Col>
              )}
              <Row.Col size="auto">
                <Text color={TextColor.initial} variant={TextVariant.normal}>
                  {item.text}
                </Text>
              </Row.Col>
            </Row>
          </ListBody>
        );
      })}
    </ListContainer>
  );
};

List.propTypes = {
  type: PropTypes.oneOf<ListTypes>([ListTypes.ORDERED, ListTypes.UNORDERED, ListTypes.BULLET_FREE])
    .isRequired,
  items: PropTypes.arrayOf<ListItemProps>(
    PropTypes.shape({
      icon: PropTypes.node,
      text: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

List.defaultProps = {
  type: ListTypes.UNORDERED
};

export default List;
