import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';
import { DOCUMENT_TYPES } from '../constants';
import getDefaultTextLang from './getDefaultTextLang';

export const getDocumentTypes = (translate?: (key: ITextLangKeys) => string) => {
  const t = !translate ? getDefaultTextLang : translate;
  return [
    { value: DOCUMENT_TYPES[0].value, label: t('dup_document_type_paystub') }, // PAYSTUB
    { value: DOCUMENT_TYPES[1].value, label: t('dup_document_type_bank_statement') }, // BANK_STATEMENT
    { value: DOCUMENT_TYPES[2].value, label: t('dup_document_type_cash_app_statement') },
    { value: DOCUMENT_TYPES[3].value, label: t('dup_document_type_credit_debit_card_statement') },
    {
      value: DOCUMENT_TYPES[4].value,
      label: t('dup_document_type_department_of_veterans_affairs_benefit_letter')
    },
    { value: DOCUMENT_TYPES[5].value, label: t('dup_document_type_investment_account') },
    {
      value: DOCUMENT_TYPES[6].value,
      label: t('dup_document_type_social_security_benefits_letter')
    },
    { value: DOCUMENT_TYPES[7].value, label: t('dup_document_type_social_security_statement') },
    { value: DOCUMENT_TYPES[8].value, label: t('dup_document_type_tax_transcript') },
    { value: DOCUMENT_TYPES[9].value, label: t('dup_document_type_utility_bill') }
  ];
};

export default getDocumentTypes;
