/**
 * This hook is used to check if a feature flag is enabled or not.
 */
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP';
import { configVars } from 'src/helpers';
import useFetch from 'src/hooks/useFetch';
const FEATURE_FLAG_API_URL = configVars.feature_flag_api_url;

type FFProjectName = 'fraud-platform' | 'enterprise-partner-api';
type AuthType = 'unauthenticated' | 'authenticated';

type FeatureFlagResponse = boolean;

export enum FeatureFlagKeys {
  applicantDupExperienceRedesign = 'applicant-dup-experience-redesign'
}

const featureFlagRoutes = {
  unathenticated: `unauthenticated/feature-flag`,
  authenticated: `feature-flag`
};

interface FFParams {
  projectName: FFProjectName;
  featureFlagKey: FeatureFlagKeys;
  authType?: AuthType;
  filter?: string;
}

export function useFeatureFlag(params: FFParams) {
  const { projectName, featureFlagKey, authType = 'unauthenticated', filter } = params;
  const url = `${FEATURE_FLAG_API_URL}/${
    authType === 'unauthenticated'
      ? featureFlagRoutes.unathenticated
      : featureFlagRoutes.authenticated
  }/${projectName}?featureFlagKey=${featureFlagKey}${filter ? `&filter=${filter}` : ''}`;

  const headers =
    authType === 'unauthenticated'
      ? {
          'x-unauthenticated-session-token':
            localStorage.getItem(UNAUTHENTICATED_SESSION_TOKEN_KEY) || ''
        }
      : {};

  return useFetch<FeatureFlagResponse>(url, 'GET', headers);
}
