import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem;
  max-width: 1280px;
  margin: 0 auto;
  input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }
`;
