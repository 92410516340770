/**
 * This hook is used to wrap an API call using axios and handle the response.
 * It will return the response data, loading state, and error state.
 */
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

export type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface DefaultResponseData {
  content?: string;
}

function useFetch<T = DefaultResponseData>(
  url: string,
  method: FetchMethod = 'GET',
  headers: Record<string, string> | object = {}
) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | string>(false);

  // Serializing the headers ensures that the useEffect hook will
  // only re-run when the headers actually change.
  const serializedHeaders = JSON.stringify(headers);

  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();
    axios({
      cancelToken: source.token,
      url,
      method: method.toLowerCase() as 'get' | 'post' | 'put' | 'delete',
      headers: JSON.parse(serializedHeaders)
    })
      .then((res: AxiosResponse<T>) => {
        if (res.data !== null && res.data !== undefined) {
          setData(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(`Error: ${err}`);
      });
    return () => {
      source.cancel();
    };
  }, [url, method, serializedHeaders]);

  return { data, loading, error };
}

export default useFetch;
