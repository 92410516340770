import PropTypes from 'prop-types';
import { StyledButton, StyledLabel } from './styles';
import { Icon } from '../../atoms/Icon/index';
import { IconColor } from '../../atoms/Icon/Icon';

export enum ButtonIconBackgroundColor {
  primary = 'primary',
  secondary = 'secondary',
  none = 'none'
}

export type ButtonIconProps = React.HTMLAttributes<HTMLButtonElement> & {
  onClick: () => void;
  name: string;
  background: keyof typeof ButtonIconBackgroundColor;
  isCircle?: boolean;
  label?: string;
  disabled?: boolean;
  ariaLabel?: string;
  icon: string;
  iconColor?: keyof typeof IconColor;
};

const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
  const { iconColor, icon, label, ...buttonProps } = props;

  return (
    <StyledButton type="button" {...buttonProps}>
      <Icon icon={icon} color={iconColor} />
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledButton>
  );
};

ButtonIcon.propTypes = {
  ariaLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  isCircle: PropTypes.bool,
  background: PropTypes.oneOf<keyof typeof ButtonIconBackgroundColor>(
    Object.values(ButtonIconBackgroundColor)
  ).isRequired,
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  iconColor: PropTypes.oneOf<keyof typeof IconColor>(Object.values(IconColor))
};

ButtonIcon.defaultProps = {
  name: 'ButtonIcon',
  background: ButtonIconBackgroundColor.primary,
  isCircle: true,
  icon: 'add',
  label: '',
  disabled: false,
  iconColor: 'white'
};

export default ButtonIcon;
