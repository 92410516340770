import { Icon, IconColor } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Spinner from 'src/components/atoms/Spinner';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { Container, Content, FLoatLabel, SubContainer } from './styles';
import { DropdownButtonProps } from './types';

const DropdownButton: React.FC<DropdownButtonProps> = ({
  onOpened,
  onClosed,
  label,
  name,
  isOpen,
  isDisabled,
  isLoading,
  size,
  hasError,
  showLabel,
  children
}: DropdownButtonProps) => {
  const handleClick = () => {
    if (!isDisabled) {
      isOpen ? onOpened() : onClosed();
    }
  };

  return (
    <Container
      className="DDButton"
      id={name}
      onClick={handleClick}
      onBlur={onClosed}
      aria-expanded={isOpen}
      aria-label={name}
      aria-disabled={isDisabled}
      role="button"
      hasError={hasError}
    >
      <SubContainer size={size} isDisabled={isDisabled} className="DDSubContainer">
        <Row justify="space-between" alignItems="center" gap={0.75} className="DDButtonContain">
          {showLabel && label !== children && (
            <FLoatLabel aria-invalid={hasError}>
              <Text variant={TextVariant.small} color={TextColor.gray700}>
                {label}
              </Text>
            </FLoatLabel>
          )}
          <Content className="DDContent">
            {children} {isLoading && <Spinner size="small" color="primary" />}
          </Content>
          <Icon color={IconColor.gray600} icon={`key-arrow-${isOpen ? 'up' : 'down'}`} />
        </Row>
      </SubContainer>
    </Container>
  );
};

export default DropdownButton;
