import styled, { css } from 'styled-components/macro';

import { TextColor, TextProps, TextVariant } from '.';

const stretchText = ({
  isStretched,
  align,
  alignItems
}: Pick<TextProps, 'isStretched' | 'align' | 'alignItems'>) => {
  if (isStretched) {
    return css`
      display: block;
      width: 100%;
      text-align: ${align};
      align-items: ${alignItems};
    `;
  }

  return css`
    display: flex;
    text-align: ${align};
    justify-content: ${align};
    align-items: ${alignItems};
  `;
};

export const TextComponent = styled.span<TextProps>`
  ${({ theme, variant = TextVariant.normal }) => theme.font[variant]};
  ${({ theme, color = TextColor.initial }) => theme.font.colors[color]};
  ${({ theme, isBold }) => isBold && theme.font.bold};
  ${stretchText};

  &.underline {
    text-decoration: underline;
  }
`;
