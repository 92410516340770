import Circle from 'src/components/atoms/Circle';
import HideOnMobile from 'src/components/DUP/atoms/HideInMobile';
import MiniDivider from 'src/components/molecules/MiniDivider';
import { ActiveText, CircleDivider, Content, Step, Text } from './styles';
type StepInformation = {
  title: string;
  stepNumber: number;
};

export type FooterProps = {
  activeStep: number;
  // Include information about each step in sequential order
  steps: StepInformation[];
  goToStep: (step: number) => void;
};

/**
 * This component displays where the user is currently in the wizard process.
 * It displays the steps in sequential order and highlights the current step.
 *
 * @param {FooterProps} props
 */
const WizardFooter: React.FC<FooterProps> = (props: FooterProps) => {
  const { activeStep, steps, goToStep } = props;
  const numberOfSteps = steps.length;

  return (
    <Content>
      {steps.map(({ title, stepNumber }, i) => {
        const currentStepActive = activeStep === stepNumber;
        return (
          <Step key={i} onClick={() => goToStep(stepNumber)}>
            <CircleDivider>
              <Circle activeColor={'#32B2FC'} isActive={currentStepActive}>
                {stepNumber}
              </Circle>
            </CircleDivider>
            {currentStepActive && <ActiveText>{title}</ActiveText>}
            {!currentStepActive && (
              <HideOnMobile>
                <Text>{title}</Text>
              </HideOnMobile>
            )}
            {!(numberOfSteps === stepNumber) && (
              <HideOnMobile>
                <MiniDivider />
              </HideOnMobile>
            )}
          </Step>
        );
      })}
    </Content>
  );
};

export default WizardFooter;
