import { Line as StyleLine } from './styles';

export type LineProps = {
  height?: 'auto' | 'tiny';
  gap?: number;
};
const Line: React.FC<LineProps> = ({ height, gap }: LineProps) => {
  return <StyleLine height={height} gap={gap} />;
};

export default Line;
