import * as S from './styles';

export type BodyProps = {
  children: React.ReactNode;
};

const Body: React.FC<BodyProps> = (props: BodyProps) => {
  const { children } = props;
  return <S.Container>{children}</S.Container>;
};

export default Body;
