import Button from 'src/components/atoms/Button';
import styled from 'styled-components/macro';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  padding-top: 1rem;
  overflow-y: auto;
`;

export const PersonalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ReviewContainer = styled.div`
  width: 100%;
  max-width: 47rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 1rem;
  }
`;

export const ReviewSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewSectionContent = styled.div`
  opacity: 0.8;
`;

export const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const Gap = styled.div`
  height: 1rem;
`;

export const Row = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Terms = styled.span`
  margin-left: 0.25rem;
`;

export const EditButton = styled(Button)`
  border-radius: 10px;
  font-family: 'Montserrat';
  line-height: 20px;
  padding: 18px;
  font-size: 16px;
  font-weight: 700;
`;

export const DocumentRow = styled.div`
  display: flex;
  line-height: 24px;
  gap: 0.4rem;
`;

export const NoDocumentsContainer = styled.div`
  background: ${({ theme }) => theme.color.gray[100]};
  padding: 16px 24px;
  width: 100%;
  margin-top: 1rem;
`;
