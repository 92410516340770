import { useState } from 'react';
import { DropdownButton } from 'src/components/DUP/atoms/DropdownButton';
import { Container, OptionContainer } from './styles';

export interface DropdownOption<T> {
  value: T;
  label: string;
}

export type DropdownProps<T> = {
  value?: T;
  options: DropdownOption<T>[];
  onChange: (value?: T) => void;
  name: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  size?: 'sm' | 'md';
  valueInitial?: string;
  labelInitial?: string;
  showLabel?: boolean | null;
  listPosition?: 'top' | 'bottom';
};

export function Dropdown<T>(props: DropdownProps<T>) {
  const {
    value,
    options,
    onChange,
    placeholder,
    size,
    valueInitial,
    showLabel,
    listPosition,
    isDisabled
  } = props;

  const [isOpenUpdated, setIsOpenUpdated] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(valueInitial !== '');

  const onChanging = (value: T) => {
    onChange(value);
    setIsOpenUpdated(false);
  };

  const onItemSelected = (value: T) => {
    onChanging(value);
    setIsItemSelected(true);
  };
  const Item = ({ value, label }: DropdownOption<T>) => {
    return (
      <button
        type="button"
        id={value as string}
        onClick={() => onItemSelected(value)}
        role="option"
        aria-selected={true}
      >
        {label}
      </button>
    );
  };

  return (
    <Container role={'dropdown'} onMouseLeave={() => setIsOpenUpdated(false)}>
      <DropdownButton
        {...props}
        showLabel={showLabel || isItemSelected}
        isOpen={isOpenUpdated}
        label={placeholder}
        onOpened={() => setIsOpenUpdated(false)}
        onClosed={() => setIsOpenUpdated(true)}
        size={size}
      >
        {placeholder}
      </DropdownButton>

      {isOpenUpdated && !isDisabled && (
        <OptionContainer
          listPosition={listPosition}
          rows={options.length}
          onMouseLeave={() => {
            setIsOpenUpdated(false);
          }}
        >
          <ul>
            {options.map((option, index) => {
              const selected = option.value === value ? 'selected' : '';
              return (
                <li key={`${value}-${index}`} className={`${selected}`}>
                  <Item value={option.value} label={option.label} />
                </li>
              );
            })}
          </ul>
        </OptionContainer>
      )}
    </Container>
  );
}

export default Dropdown;
