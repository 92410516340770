import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const PointLeft = styled.span`
  font-weight: 900;
  font-size: 2rem;
  position: absolute;
  top: -19px;
  left: -5px;
`;

export const PointRight = styled.span`
  font-weight: 900;
  font-size: 2rem;
  position: absolute;
  top: -19px;
  right: -5px;
`;
