import { colors, devices } from './constants';
import { mainButtons } from './custom/buttons';
import { mainNavigation } from './custom/navigation';
import { mainTooltip } from './custom/tooltip';
import { mainFonts } from './custom/typography';
import './style.scss';
const theme = {
  button: mainButtons,
  font: mainFonts,
  color: colors,
  device: devices,
  navigation: mainNavigation,
  tooltip: mainTooltip
};
export default theme;
