import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text from 'src/components/atoms/Text';
import { StyledButton as Button } from 'src/pages/DUPPage/styles';
import styled from 'styled-components';

export const CenteredColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}rem` : 'unset')};
`;

export const Container = styled(CenteredColumn)`
  flex: 1;
  width: 100%;
  max-width: 512px;
  margin: 0 auto;
  padding-top: 1rem;
`;

export const StyledRow = styled(Row)`
  margin: auto;
  gap: 2.5rem;
  @media ${({ theme }) => theme.device.mobile} {
    gap: 2rem;
  }
`;

export const UploadsContainer = styled(CenteredColumn)`
  gap: 4px;
  border-radius: 8px;
  border: 1px dashed #344054;
  background: #fff;
  width: 100%;
  margin: 0.5rem 1rem;
  padding: 1rem 1.5rem;

  color: #475467;
  font-family: Inter;
  font-size: 14px;
`;

export const StyledIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e4e7ec;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const StyledText = styled(Text)`
  line-height: 20px;
  & span {
    font-weight: bold;
    color: #007fc9;
    margin-right: 4px;
    cursor: pointer;
  }
`;

export const SubText = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const HelpText = styled(Text)`
  width: 100%;
  text-align: left;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  & span {
    color: #0067a3;
    cursor: pointer;
    margin-left: 4px;
  }
`;

export const StyledTrigger = styled.span`
  cursor: pointer;
`;

export const FileContainer = styled(Row)`
  width: 100%;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid #e3e8ef;
  background: #fff;
  margin-top: 1rem;

  & span {
    overflow: hidden;
    color: #364152;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    line-height: 20px;
    max-width: 400px;
    @media ${({ theme }) => theme.device.mobile} {
      max-width: 300px;
    }
  }

  & > div {
    flex-direction: row !important;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: row !important;
  }
`;

export const ButtonsContainer = styled(Row)`
  width: 100%;
  margin-top: 2rem;
  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 142px;
  height: 45px;
  justify-content: center;
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 1rem;
    width: 100%;
  }
`;
