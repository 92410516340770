import PropTypes from 'prop-types';
import { Icon } from 'src/components/atoms/Icon';
import Text, { TextColor, TextVariant } from '../../atoms/Text';
import { Content, ContentIcon } from './styles';

export enum ResultMessageType {
  error = 'error',
  success = 'success',
  warning = 'warning'
}

export type ResultMessageProps = { title: string; message?: string; type: ResultMessageType };

const ResultMessage: React.FC<ResultMessageProps> = (props: ResultMessageProps) => {
  const { message, title, type } = props;
  return (
    <Content>
      <ContentIcon type={type}>
        {type === ResultMessageType.success && <Icon icon="icon-success" />}
        {type === ResultMessageType.warning && <Icon icon="icon-info" />}
        {type === ResultMessageType.error && <Icon icon="icon-error" />}
      </ContentIcon>
      {title && (
        <Text color={TextColor.gray600} variant={TextVariant.h3} isBold>
          {title}
        </Text>
      )}
      {message && (
        <Text color={TextColor.gray600} variant={TextVariant.normal}>
          {message}
        </Text>
      )}
    </Content>
  );
};

ResultMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  type: PropTypes.oneOf<ResultMessageType>(Object.values(ResultMessageType)).isRequired
};

ResultMessage.defaultProps = {
  title: 'Some title',
  message: 'Some message',
  type: ResultMessageType.error
};

export default ResultMessage;
