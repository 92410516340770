import { colors } from '../constants';
import navigation from '../navigation';
import '../style.scss';

export const mainNavigation = {
  ...navigation,
  color: colors.white,
  background: colors.snappt[500],
  borderColor: colors.snappt[500],
  hover: {
    ...navigation.hover,
    background: colors.snappt[100],
    borderColor: colors.snappt[100]
  },
  active: {
    ...navigation.active,
    background: colors.snappt[700],
    borderColor: colors.white
  }
};

export default navigation;
