import styled, { keyframes } from 'styled-components';
import { LoaderProps } from './Loader';

const squareAnimation = keyframes`
  0%, 10% {
    bottom: 0;
  }
  25% {
    bottom: 1.125rem;
  }
  50% {
    bottom: 2.25rem;
  }
  75% {
    bottom: 3.375rem;
  }
  100% {
    bottom: 0;
  }
`;

const triangleOneAnimation = keyframes`
  0%, 10%, 25% {
    bottom: 0;
  }
  50% {
    bottom: 1.125rem;
  }
  75% {
    bottom: 2.25rem;
  }
  100% {
    bottom: 0;
  }
`;

const triangleTwoAnimation = keyframes`
  0%, 10%, 25%, 50% {
    bottom: 0;
  }
  75% {
    bottom: 1.125rem;
  }
  100% {
    bottom: 0;
  }
`;

export const ContainerLoader = styled.div<LoaderProps>`
  width: 100%;
  height: 100%;
  position: ${({ isFixed }) => isFixed && 'fixed'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled.div`
  width: 5.2rem;
  & .square {
    position: absolute;
    bottom: 0;
    animation: ${squareAnimation} 2.5s linear infinite;
  }
  & .triangle_one {
    position: absolute;
    bottom: 0;
    animation: ${triangleOneAnimation} 2.5s linear infinite;
  }
  & .triangle_two {
    position: absolute;
    bottom: 0;
    animation: ${triangleTwoAnimation} 2.5s linear infinite;
  }
  & .triangle_three {
    position: absolute;
    bottom: 0;
  }
`;
