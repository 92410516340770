import PropTypes from 'prop-types';
import { devices } from 'src/theme/constants';
import { Column } from './styles';

export type AlignSelf = 'unset' | 'flex-start' | 'flex-end' | 'center' | 'stretch';
export type AlignContent = 'left' | 'right' | 'center';

export type ColProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  size?: number | string;
  collapse?: keyof typeof devices;
  alignSelf?: AlignSelf;
  alignContent?: AlignContent;
};

const Col: React.FC<ColProps> = (props: ColProps) => {
  return <Column {...props}>{props.children}</Column>;
};

Col.propTypes = {
  children: PropTypes.node.isRequired
};

export default Col;
