import { colors, fonts } from './constants';
import './style.scss';

const typography = {
  span: {
    fontFamily: fonts.normal,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    margin: '0rem'
  },
  normal: {
    fontFamily: fonts.normal,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    margin: '0rem'
  },
  bold: {
    fontFamily: fonts.bold
  },
  big: {
    fontFamily: fonts.normal,
    fontSize: '3.375rem',
    lineHeight: '3.9375rem',
    fontWeight: 300,
    color: colors.black.normal
  },
  small: {
    fontFamily: fonts.normal,
    fontSize: '0.875rem',
    lineHeight: '1.2rem',
    fontWeight: 400,
    color: colors.black.normal
  },
  h1: {
    fontFamily: fonts.normal,
    fontSize: '2.5rem',
    fontWeight: 400,
    color: colors.black.normal,
    margin: '0rem'
  },
  h2: {
    fontFamily: fonts.normal,
    fontSize: '2.125rem',
    fontWeight: 400,
    color: colors.black.normal,
    margin: '0rem'
  },
  h3: {
    fontFamily: fonts.normal,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: colors.black.normal,
    margin: '0rem'
  },
  h4: {
    fontFamily: fonts.normal,
    fontSize: '1.125rem',
    fontWeight: 400,
    color: colors.black.normal,
    margin: '0rem'
  },
  colors: {
    inherit: {
      color: 'inherit'
    },
    initial: {
      color: colors.black.normal
    },
    primary: {
      color: colors.black.normal
    },
    secondary: {
      color: colors.black.light
    },
    success: {
      color: colors.green[700]
    },
    error: {
      color: colors.red[700]
    },
    warning: {
      color: colors.yellow[700]
    },
    info: {
      color: colors.blue[700]
    },
    muted: {
      color: colors.gray[300]
    },
    gray100: {
      color: colors.gray[100]
    },
    gray200: {
      color: colors.gray[200]
    },
    gray300: {
      color: colors.gray[300]
    },
    gray500: {
      color: colors.gray[500]
    },
    gray600: {
      color: colors.gray[600]
    },
    gray700: {
      color: colors.gray[700]
    },
    white: {
      color: colors.white
    }
  }
};

export default typography;
