import React from 'react';
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import { Icon, IconColor } from 'src/components/atoms/Icon';
import Row from 'src/components/atoms/Row/Row';
import Text, { TextColor } from 'src/components/atoms/Text';
import useLanguage from 'src/context/Language/useLanguage';
import { DupApplicationType } from 'src/types/api';

export type LanguageSelectorProps = {
  type: DupApplicationType;
  onSetLanguage: (lang: 'EN' | 'ES', type: DupApplicationType) => void;
  iconColor?: IconColor;
  textColor?: TextColor;
  TextWrapper?: React.FC<{ children: React.ReactNode }> | null;
};
const LanguageSelector: React.FC<LanguageSelectorProps> = (props: LanguageSelectorProps) => {
  const {
    type,
    onSetLanguage,
    iconColor = IconColor.primary,
    textColor = TextColor.primary,
    TextWrapper
  } = props;
  const { translate: t, language } = useLanguage();
  const isEnglish = language === 'EN';

  const TextContent = (
    <Text color={textColor} isBold>
      {isEnglish ? t('language_es') : t('language_en')}
    </Text>
  );

  return (
    <Button
      name={`LanguageSelector_${language}`}
      onClick={() => {
        onSetLanguage(isEnglish ? 'ES' : 'EN', type);
      }}
      variant={ButtonVariant.ghost}
    >
      <Row gap={0.5} alignItems="center">
        {TextWrapper && <TextWrapper>{TextContent}</TextWrapper>}
        {!TextWrapper && <>{TextContent}</>}

        <Icon icon="language" color={iconColor} />
      </Row>
    </Button>
  );
};

export default LanguageSelector;
