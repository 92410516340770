import styled, { css } from 'styled-components';

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CircleDivider = styled.div`
  padding-right: 0.5rem;
  @media ${({ theme }) => theme.device.mobile} {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
`;

const allText = css`
  font-weight: 700;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 12px;
  }
`;

export const ActiveText = styled.div`
  color: #32b2fc;
  ${allText}
`;

export const Text = styled.div`
  ${allText}
`;
